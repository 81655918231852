import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AppState } from 'store';
import { GetMatchModelExt } from '../core/entities';
import { MatchesService } from '../core/matches.service';
import { NgFor, NgIf } from '@angular/common';
import { MatchBoxComponent } from '../shared/match-box/match-box.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    templateUrl: './hall-of-shame.component.html',
    styleUrls: ['./hall-of-shame.component.scss'],
    imports: [NgFor, MatchBoxComponent, NgIf, TranslateModule],
})
export class HallOfShameComponent implements OnInit {
    public matches: Array<GetMatchModelExt> = [];
    constructor(
        private readonly matchesService: MatchesService,
        private readonly store: Store<AppState>,
    ) {}

    ngOnInit() {
        this.store
            .select((x) => x.season.active)
            .pipe(
                filter((x) => x !== null),
                distinctUntilChanged((a, b) => a.id === b.id),
                switchMap((s) => this.matchesService.load(10000, 0, s.id, 'shame')),
            )
            .subscribe((x) => (this.matches = x));
    }
}
