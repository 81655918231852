import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { PlayerStatisticsCollectionModel, PlayerStatisticsModel, Season, TeamSettings } from 'models';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { InviteModalComponent } from 'shared/invite-modal/invite-modal.component';
import { AppState, playersActions } from 'store';
import { selectSettings } from 'store/players/players.selectors';
import { IPlayerWinRate } from '../store/players/players.model';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { NgFor, NgIf, NgClass, AsyncPipe, DecimalPipe } from '@angular/common';
import { MatRipple } from '@angular/material/core';
import { PlayerPerformanceComponent } from './player-performance/player-performance.component';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarUrlPipe } from '../shared/pipes/avatar-url.pipe';

@Component({
    selector: 'app-players',
    templateUrl: './players.component.html',
    styleUrls: ['./players.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButton,
        MatIcon,
        NgFor,
        MatRipple,
        PlayerPerformanceComponent,
        NgIf,
        MatTooltip,
        NgClass,
        TranslateModule,
        AsyncPipe,
        DecimalPipe,
        AvatarUrlPipe,
    ],
})
export class PlayersComponent implements OnInit, OnDestroy {
    public players$: Observable<Array<PlayerStatisticsCollectionModel>>;
    public detail$: Observable<PlayerStatisticsModel>;
    public detailId$: Observable<number>;
    private readonly _unsubscribe = new Subject<void>();

    private _season: Season = null;
    public activePlayer: number = null;

    public sortCol: keyof IPlayerWinRate = 'displayName';
    public sortColAsc = true;

    public teamSettings: TeamSettings;

    constructor(
        private readonly store: Store<AppState>,
        private readonly matDialog: MatDialog,
    ) {}

    ngOnInit() {
        this.store
            .select(selectSettings)
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((settings) => {
                this.teamSettings = settings;
            });
        this.players$ = this.store.pipe(select((x) => x.players.stats));
        this.detail$ = this.store.pipe(
            select((x) => x.players.statsDetail),
            tap((x) => (this.activePlayer = x ? x.id : null)),
        );

        this.store
            .pipe(
                select((x) => x.season.active),
                filter((x) => x !== null),
                takeUntil(this._unsubscribe),
            )
            .subscribe((s) => {
                if (s) {
                    this._season = s;
                }
                this.store.dispatch(playersActions.loadPlayersStats({ season: s }));
                if (this.activePlayer) {
                    this.store.dispatch(playersActions.loadPlayersStatsDetail({ idPlayer: this.activePlayer, season: this._season }));
                }
            });
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    showDetail(player: PlayerStatisticsModel) {
        if (player.id !== this.activePlayer) {
            this.store.dispatch(playersActions.loadPlayersStatsDetail({ idPlayer: player.id, season: this._season }));
        }
        this.activePlayer = null;
    }

    public sortWinRate($event: MouseEvent, col: keyof IPlayerWinRate) {
        $event.stopPropagation();
        if (this.sortCol === col) {
            this.sortColAsc = !this.sortColAsc;
        } else {
            this.sortColAsc = true;
        }
        this.sortCol = col;
        this.store.dispatch(playersActions.sortWinRate({ col: col, asc: this.sortColAsc }));
    }

    public getSortClass(col: keyof IPlayerWinRate) {
        if (this.sortCol !== col) {
            return ['fa-sort', 'text-muted'];
        } else if (this.sortColAsc) {
            return ['fa-sort-up'];
        } else {
            return ['fa-sort-down'];
        }
    }

    public openInviteModal() {
        this.matDialog
            .open(InviteModalComponent, { autoFocus: false })
            .afterClosed()
            .subscribe(() => {
                this.store.dispatch(playersActions.loadPlayers({ force: true }));

                this.store
                    .pipe(
                        select((x) => x.season.active),
                        filter((x) => x !== null),
                        take(1),
                    )
                    .subscribe((s) => {
                        this.store.dispatch(playersActions.loadPlayersStats({ season: s }));
                        if (this.activePlayer) {
                            this.store.dispatch(
                                playersActions.loadPlayersStatsDetail({ idPlayer: this.activePlayer, season: this._season }),
                            );
                        }
                    });
            });
    }
}
