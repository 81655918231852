import { Component, Input } from '@angular/core';
import { PlayerStatisticsPerformanceModel } from 'models';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-player-performance',
    templateUrl: './player-performance.component.html',
    styleUrls: ['./player-performance.component.scss'],
    imports: [NgFor, NgIf],
})
export class PlayerPerformanceComponent {
    @Input()
    public performance: Array<PlayerStatisticsPerformanceModel> = [];
}
