import { Component } from '@angular/core';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    imports: [SidebarComponent],
})
export class NotificationsComponent {}
