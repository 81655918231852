import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import {
    enableProdMode,
    ErrorHandler,
    importProvidersFrom,
    inject,
    Injectable,
    Injector,
    LOCALE_ID,
    provideAppInitializer,
} from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AppHttpInterceptor } from 'app-http-interceptor';
import { AppMaterialModule } from 'app-material.module';
import { fooswarsAppInitializer } from 'core/app-initializer';
import { AuthInterceptor } from 'core/auth.interceptor';
import { BrowserStorageService } from 'core/browser-storage.service';
import { authorizedGuard } from 'core/guards';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { effects, metaReducers, reducers } from 'store';
import { FormsModule } from '../node_modules/@angular/forms';
import { AppComponent } from './app/app.component';
import { CoreModule } from './app/core/core.module';
import { DashboardComponent } from './app/dashboard/dashboard.component';
import { ApplicationErrorHandler } from './app/error-handler';
import { HallOfShameComponent } from './app/hall-of-shame/hall-of-shame.component';
import { NotificationsComponent } from './app/notifications/notifications.component';
import { PlayersComponent } from './app/players/players.component';
import { SharedModule } from './app/shared/shared.module';
import { environment } from './environments/environment';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', `.json?v=${environment.version}`);
}

@Injectable({ providedIn: 'root' })
class InjectableTranslateMessageFormatCompiler extends TranslateMessageFormatCompiler {}
const routes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
    },
    {
        path: 'account',
        loadChildren: () => import('./app/account/routes').then((m) => m.routes),
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.DASHBOARD',
        },
    },
    {
        path: 'players',
        component: PlayersComponent,
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.PLAYERS',
        },
    },
    {
        path: 'statistics',
        loadChildren: () => import('./app/statistics/routes').then((m) => m.routes),
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.STATISTICS',
        },
    },
    {
        path: 'hall-of-shame',
        component: HallOfShameComponent,
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.HALL_OF_SHAME',
        },
    },
    {
        path: 'settings',
        loadChildren: () => import('./app/settings/routes').then((m) => m.routes),
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.SETTINGS',
        },
    },
    {
        path: 'achievements',
        loadChildren: () => import('./app/achievements/achievements.module').then((m) => m.AchievementsModule),
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.ACHIEVEMENTS',
        },
    },
    {
        path: 'widgets',
        loadChildren: () => import('./app/widgets/routes').then((m) => m.routes),
        canActivate: [authorizedGuard],
    },
    {
        path: 'notifications',
        component: NotificationsComponent,
        canActivate: [authorizedGuard],
        data: {
            pageTitle: 'PAGE_TITLE.NOTIFICATIONS',
        },
    },
    {
        path: '**',
        redirectTo: '/dashboard',
    },
];

if (environment.production) {
    enableProdMode();
}
registerLocaleData(localeCs, 'cs');

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: createTranslateLoader,
                    deps: [HttpClient],
                },
                compiler: {
                    provide: TranslateCompiler,
                    useClass: InjectableTranslateMessageFormatCompiler,
                },
            }),
            BrowserModule,
            CoreModule,
            SharedModule,
            InfiniteScrollModule,
            ServiceWorkerModule.register('/ngsw-worker.js', {
                enabled: environment.production,
                registrationStrategy: 'registerWhenStable:30000',
            }),
            FormsModule,
            StoreModule.forRoot(reducers, {
                metaReducers: metaReducers,
                runtimeChecks: {
                    strictActionImmutability: false,
                    strictStateImmutability: false,
                },
            }),
            EffectsModule.forRoot(effects),
            StoreDevtoolsModule.instrument({
                maxAge: 25,
                logOnly: environment.production,
                connectInZone: true,
            }),
            AppMaterialModule,
            SweetAlert2Module.forRoot({
                provideSwal: () =>
                    import('sweetalert2').then(({ default: swal }) =>
                        swal.mixin({
                            backdrop: false,
                            theme: 'dark',
                        }),
                    ),
            }),
        ),
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: (tran: TranslateService) => tran.currentLang || 'cs',
        },
        {
            provide: ErrorHandler,
            useClass: ApplicationErrorHandler,
            deps: [Injector],
        },
        { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['cs', 'en'] } },
        provideAppInitializer(() => {
            const initializerFn = fooswarsAppInitializer(inject(BrowserStorageService));
            return initializerFn();
        }),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(routes),
        provideAnimations(),
    ],
})
    // eslint-disable-next-line no-console
    .catch((err) => console.log(err));
